import { useState } from "react";
import {
  Calculator,
  DollarSign,
  HelpCircle,
  X,
  Check,
  BadgeCheck,
  Sparkles,
  Rocket,
} from "lucide-react";
import mixpanel from "mixpanel-browser";
import { config } from "../../config";

const CostCalculator = () => {
  const [currentSpend, setCurrentSpend] = useState("");
  const [currentSetup, setCurrentSetup] = useState("agency");
  const [showGuide, setShowGuide] = useState(false);

  const calculateCosts = () => {
    const spend = parseFloat(currentSpend) || 0;

    // Calculate management costs
    const managementCosts = {
      agency: {
        base: 8000,
        percentage: spend * 0.15,
        total: 8000 + spend * 0.15,
      },
      inHouse: {
        base: 14000 + (spend > 50000 ? (spend - 50000) * 0.05 : 0),
        get total() {
          return this.base;
        },
      },
      adInsights: {
        base:
          spend <= 50000
            ? 49.99 // Starter Plan
            : spend <= 150000
            ? 99.99 // Growth Plan
            : spend <= 300000
            ? 999.99 // Pro Plan
            : 4999.99, // Scale Plan
        total:
          spend <= 50000
            ? 49.99 // Starter Plan
            : spend <= 150000
            ? 99.99 // Growth Plan
            : spend <= 300000
            ? 999.99 // Pro Plan
            : 4999.99, // Scale Plan
      },
    };

    // Calculate total costs (ad spend + management)
    const totalCosts = {
      agency: spend + managementCosts.agency.total,
      inHouse: spend + managementCosts.inHouse.total,
      adInsights: spend + managementCosts.adInsights.total,
    };

    return {
      spend,
      management: managementCosts,
      total: totalCosts,
    };
  };

  const costs: any = calculateCosts();
  const savings = costs.total[currentSetup] - costs.total.adInsights;
  const savingsPercentage =
    costs.spend > 0
      ? ((savings / costs.total[currentSetup]) * 100).toFixed(0)
      : 0;

  const Guide = () => (
    <div className="fixed inset-0 bg-gray-900/80 backdrop-blur-sm flex items-center justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white rounded-xl p-4 sm:p-6 md:p-8 max-w-2xl w-full relative shadow-2xl mx-4 my-8 sm:my-4">
        <button
          onClick={() => setShowGuide(false)}
          className="absolute top-3 right-3 sm:top-4 sm:right-4 text-gray-500 hover:text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-full p-1.5 sm:p-2 transition-colors duration-200"
          aria-label="Close guide"
        >
          <X className="h-4 w-4 sm:h-5 sm:w-5" />
        </button>

        <h3 className="text-lg sm:text-xl font-bold mb-4 sm:mb-6 text-gray-900 flex items-center gap-2 pr-8">
          <Calculator className="h-5 w-5 text-red-600 flex-shrink-0" />
          <span>How to Use the Cost Calculator</span>
        </h3>

        <div className="space-y-4 sm:space-y-6 overflow-y-auto max-h-[70vh] pr-1">
          <div className="bg-gray-50 p-3 sm:p-4 rounded-lg border-l-4 border-red-500">
            <h4 className="font-semibold mb-1 sm:mb-2 text-gray-900 text-sm sm:text-base">
              Step 1: Enter Your Monthly Ad Spend
            </h4>
            <p className="text-gray-600 text-sm sm:text-base">
              Enter the total amount you spend on ads across all platforms
              monthly.
            </p>
          </div>

          <div className="bg-gray-50 p-3 sm:p-4 rounded-lg border-l-4 border-red-500">
            <h4 className="font-semibold mb-1 sm:mb-2 text-gray-900 text-sm sm:text-base">
              Step 2: Select Your Current Setup
            </h4>
            <p className="text-gray-600 text-sm sm:text-base">
              Choose between:
            </p>
            <ul className="list-disc ml-5 sm:ml-6 text-gray-600 mt-1 sm:mt-2 space-y-1 text-sm sm:text-base">
              <li>Agency (15% of ad spend + $8K/mo retainer)</li>
              <li>In-house team ($14K/mo fixed cost)</li>
            </ul>
          </div>

          <div className="bg-gray-50 p-3 sm:p-4 rounded-lg border-l-4 border-red-500">
            <h4 className="font-semibold mb-1 sm:mb-2 text-gray-900 text-sm sm:text-base">
              Understanding Your Costs
            </h4>
            <ul className="list-disc ml-5 sm:ml-6 text-gray-600 space-y-1 text-sm sm:text-base">
              <li>Traditional Agency: Base retainer + % of ad spend</li>
              <li>In-house Team: Fixed monthly cost</li>
              <li>AdInsights.ai: Tiered pricing based on ad spend</li>
            </ul>
          </div>

          <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 sm:p-6 rounded-xl border border-gray-200">
            <h4 className="font-semibold mb-2 sm:mb-3 text-gray-900 text-sm sm:text-base">
              Example Calculation
            </h4>
            <p className="text-gray-700 mb-2 text-sm sm:text-base">
              For $20,000 monthly ad spend:
            </p>
            <div className="space-y-2 text-xs sm:text-sm">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-1 border-b border-gray-200">
                <span>Agency:</span>
                <span className="font-mono mt-1 sm:mt-0">
                  $20,000 + ($8,000 + 15% fee) = $31,000 total
                </span>
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-1 border-b border-gray-200">
                <span>AdInsights.ai:</span>
                <span className="font-mono mt-1 sm:mt-0">
                  $20,000 + $49.99 = $20,049.99 total
                </span>
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-1 font-medium text-red-600">
                <span>Monthly Savings:</span>
                <span className="font-mono mt-1 sm:mt-0">
                  $10,950.01 (35% reduction)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white p-8 rounded-xl shadow-lg border border-gray-200">
      <div className="flex justify-between items-center mb-8">
        <h4 className="text-xl font-semibold flex items-center gap-2 text-gray-900">
          <Calculator className="h-6 w-6 text-red-600" />
          Cost Savings Calculator
        </h4>
        <button
          onClick={() => setShowGuide(true)}
          className="text-gray-500 hover:text-gray-700 bg-gray-100 hover:bg-gray-200 p-2 rounded-full transition-colors duration-200"
        >
          <HelpCircle className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-8">
        {/* Ad Spend Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Your Current Monthly Ad Spend
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500" />
            <input
              type="number"
              value={currentSpend}
              onChange={(e) => setCurrentSpend(e.target.value)}
              className="pl-10 w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all duration-200"
              placeholder="Enter amount"
            />
          </div>
        </div>

        {/* Setup Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-3">
            Your Current Setup
          </label>
          <div className="flex gap-4">
            <button
              onClick={() => setCurrentSetup("agency")}
              className={`px-6 py-3 text-sm rounded-lg transition-all duration-300 flex items-center gap-2 ${
                currentSetup === "agency"
                  ? "bg-gradient-to-r from-red-600 to-red-700 text-white shadow-md"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
            >
              <span
                className={`w-5 h-5 rounded-full flex items-center justify-center ${
                  currentSetup === "agency" ? "bg-white" : "bg-gray-200"
                }`}
              >
                <Check
                  className={`h-3 w-3 ${
                    currentSetup === "agency" ? "text-red-600" : "text-gray-400"
                  }`}
                />
              </span>
              Agency
            </button>
            <button
              onClick={() => setCurrentSetup("inHouse")}
              className={`px-6 py-3 text-sm rounded-lg transition-all duration-300 flex items-center gap-2 ${
                currentSetup === "inHouse"
                  ? "bg-gradient-to-r from-red-600 to-red-700 text-white shadow-md"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
            >
              <span
                className={`w-5 h-5 rounded-full flex items-center justify-center ${
                  currentSetup === "inHouse" ? "bg-white" : "bg-gray-200"
                }`}
              >
                <Check
                  className={`h-3 w-3 ${
                    currentSetup === "inHouse"
                      ? "text-red-600"
                      : "text-gray-400"
                  }`}
                />
              </span>
              In-House Team
            </button>
          </div>
        </div>

        {/* Results */}
        {parseFloat(currentSpend) > 0 && (
          <div className="space-y-6 pt-6 border-t border-gray-100">
            {/* Cost Breakdown */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Current Costs Card */}
              <div className="p-6 bg-gray-50 rounded-xl border border-gray-200">
                <div className="text-gray-700 mb-4 font-medium flex items-center gap-2">
                  <span className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                    <DollarSign className="h-4 w-4 text-gray-700" />
                  </span>
                  Current Monthly Cost
                </div>
                <div className="space-y-3">
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-gray-600">Ad Spend:</span>
                    <span className="font-mono text-right font-medium">
                      ${costs.spend.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-gray-600">Management:</span>
                    <span className="font-mono text-right font-medium break-all">
                      ${costs.management[currentSetup].total.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between font-semibold pt-3 border-t border-gray-200">
                    <span>Total:</span>
                    <span className="font-mono text-right break-all">
                      ${costs.total[currentSetup].toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>

              {/* AdInsights Costs Card */}
              <div className="p-6 bg-red-50 rounded-xl border border-red-100">
                <div className="text-gray-700 mb-4 font-medium flex items-center gap-2">
                  <span className="w-8 h-8 rounded-full bg-red-100 flex items-center justify-center">
                    <DollarSign className="h-4 w-4 text-red-600" />
                  </span>
                  AdInsights.ai Cost
                </div>
                <div className="space-y-3">
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-gray-600">Ad Spend:</span>
                    <span className="font-mono text-right font-medium">
                      ${costs.spend.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-gray-600">Platform:</span>
                    <span className="font-mono text-right font-medium break-all">
                      ${costs.management.adInsights.total.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between font-semibold pt-3 border-t border-red-200">
                    <span>Total:</span>
                    <span className="font-mono text-red-600 text-right break-all">
                      ${costs.total.adInsights.toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Monthly Savings */}
            <div className="bg-gradient-to-r from-gray-900 to-black text-white p-6 rounded-xl relative overflow-hidden">
              <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-bl from-red-600/20 to-transparent rounded-bl-full"></div>

              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 relative">
                <div>
                  <div className="text-lg font-bold mb-1">Monthly Savings</div>
                  <div className="text-sm text-gray-300 flex items-center gap-1">
                    <span className="flex items-center justify-center bg-red-500/20 text-red-300 rounded-full w-5 h-5">
                      %
                    </span>
                    <span>{savingsPercentage}% cost reduction</span>
                  </div>
                </div>
                <div className="text-3xl font-bold font-mono w-full sm:w-auto text-right break-all flex sm:block items-center justify-between">
                  <span className="text-sm font-normal text-gray-300 sm:hidden">
                    Savings:
                  </span>
                  <span>${savings.toLocaleString()}</span>
                </div>
              </div>

              <div className="w-full h-1 bg-gray-700 rounded-full mt-6 overflow-hidden">
                <div
                  className="h-full bg-gradient-to-r from-red-500 to-red-600 rounded-full"
                  style={{
                    width: `${Math.min(Number(savingsPercentage), 100)}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showGuide && <Guide />}
    </div>
  );
};

const EnhancedPricingSection = () => {
  const [showCalculator, setShowCalculator] = useState(false);
  const [billingPeriod, setBillingPeriod] = useState("monthly");

  const handlePlanClick = (isEnterprise: boolean) => {
    if (process.env.NODE_ENV === "production") {
      mixpanel.track(isEnterprise ? "Enterprise Contact" : "Plan Selected");
    }
  };

  const pricingPlans: any = [
    {
      name: "Starter",
      price: "$49.99",
      yearlyPrice: "$44.99",
      icon: <BadgeCheck className="h-6 w-6" />,
      features: [
        "All platforms",
        "Up to 6 projects created",
        "Up to 6 ICPs generated monthly",
        "Up to 4 ad strategies generated monthly",
        "Up to 4 campaign insights reports monthly",
        "Up to 2 competitor analysis reports monthly",
        "Cross-platform insights",
        "Replaces $7K+/mo in agency costs",
        "Up to 50 AI Q&A queries/month",
      ],
    },
    {
      name: "Growth",
      price: "$99.99",
      yearlyPrice: "$89.99",
      highlighted: true,
      icon: <Sparkles className="h-6 w-6" />,
      features: [
        "All platforms",
        "Up to 10 projects created",
        "Up to 10 ICPs generated monthly",
        "Up to 25 ad strategies generated monthly",
        "Up to 10 campaign insights reports monthly",
        "Up to 6 competitor analysis reports monthly",
        "Cross-platform insights",
        "Replaces $12K+/mo in agency costs",
        "Up to 200 AI Q&A queries/month",
      ],
      isEnterprise: false,
    },
    {
      name: "Pro",
      price: "$999.99",
      yearlyPrice: "$899.99",
      icon: <Rocket className="h-6 w-6" />,
      features: [
        "All platforms",
        "Up to 25 projects created",
        "Up to 50 ICPs generated monthly",
        "Up to 50 ad strategies generated monthly",
        "Up to 25 campaign insights reports monthly",
        "Up to 12 competitor analysis reports monthly",
        "Cross-platform insights",
        "Replaces $18K+/mo in agency costs",
        "Up to 500 AI Q&A queries/month",
      ],
      isEnterprise: false,
    },
    {
      name: "Scale",
      price: "$4,999.99",
      yearlyPrice: "$4,499.99",
      icon: <Rocket className="h-6 w-6" />,
      features: [
        "All platforms",
        "Unlimited projects created",
        "Up to 100 ICPs generated monthly",
        "Up to 100 ad strategies generated monthly",
        "Unlimited campaign insights reports",
        "Unlimited competitor analysis reports",
        "Cross-platform insights",
        "Replaces $22K+/mo in agency costs",
        "Unlimited AI Q&A queries",
      ],
      isEnterprise: true,
    },
  ];

  return (
    <section className="mb-20">
      <div className="text-center mb-16">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">
          Simple, Transparent Pricing
        </h2>
        <p className="text-xl text-gray-600 mb-6">
          Save Hours and Replace $100K+ Annual Costs with AI-Driven Optimization
        </p>
        <p className="text-gray-600 max-w-3xl mx-auto">
          Our clients save countless hours and an average of $9,750/month
          compared to agency retainers and $14,200/month vs in-house teams
          (salaries + tools + training)
        </p>
      </div>

      {/* Billing Toggle */}
      <div className="flex justify-center mb-12">
        <div className="bg-gray-100 p-1 rounded-xl inline-flex">
          <button
            onClick={() => setBillingPeriod("monthly")}
            className={`px-6 py-2 rounded-lg text-sm font-medium transition-all duration-300 ${
              billingPeriod === "monthly"
                ? "bg-white text-gray-900 shadow-sm"
                : "text-gray-600 hover:text-gray-900"
            }`}
          >
            Monthly
          </button>
          <button
            onClick={() => setBillingPeriod("yearly")}
            className={`px-6 py-2 rounded-lg text-sm font-medium transition-all duration-300 flex items-center gap-2 ${
              billingPeriod === "yearly"
                ? "bg-white text-gray-900 shadow-sm"
                : "text-gray-600 hover:text-gray-900"
            }`}
          >
            Annual
            <span className="bg-green-100 text-green-800 text-xs px-2 py-0.5 rounded-full">
              Save 10%
            </span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto mb-12">
        {pricingPlans.map((plan: any, index: number) => (
          <div
            key={index}
            className={`bg-white rounded-xl overflow-hidden transition-all duration-300 hover:shadow-xl relative flex flex-col ${
              plan.highlighted
                ? "ring-2 ring-red-600 shadow-lg"
                : plan.isEnterprise
                ? "ring-2 ring-gray-900 shadow-md"
                : "border border-gray-200 shadow-sm"
            }`}
          >
            {/* Ribbon for highlighted plan */}
            {plan.highlighted && (
              <div className="absolute top-0 right-0">
                <div className="bg-red-600 text-white py-1 px-4 text-xs font-bold uppercase transform rotate-45 translate-x-2 -translate-y-1 shadow-sm">
                  Popular
                </div>
              </div>
            )}

            <div className="p-6 flex-1 flex flex-col">
              <div className="mb-6">
                <div className="flex items-center gap-2 mb-2">
                  <span
                    className={`p-2 rounded-lg ${
                      plan.highlighted
                        ? "bg-red-50 text-red-600"
                        : plan.isEnterprise
                        ? "bg-gray-900 text-white"
                        : "bg-gray-100 text-gray-700"
                    }`}
                  >
                    {plan.icon}
                  </span>
                  <h3 className="text-xl font-semibold">{plan.name}</h3>
                </div>
                <p className="text-3xl font-bold flex items-end">
                  {billingPeriod === "yearly" ? plan.yearlyPrice : plan.price}
                  <span className="text-base font-normal text-gray-600 ml-1">
                    {!plan.isEnterprise && "/month"}
                  </span>
                </p>
                {plan.name === "Starter" && (
                  <p className="text-sm text-red-600 mt-2">
                    Best for: Solopreneurs & Startups
                  </p>
                )}
                {plan.name === "Scale" && (
                  <p className="text-sm text-red-600 mt-2">
                    Best for: Established SMBs & Marketing Teams
                  </p>
                )}
              </div>

              <div className="mb-8">
                {plan.features.map((feature: string, i: number) => (
                  <div key={i} className="flex items-start gap-2 mb-4">
                    <Check
                      className={`h-5 w-5 flex-shrink-0 mt-0.5 ${
                        plan.highlighted ? "text-red-600" : "text-black"
                      }`}
                    />
                    <span className="text-gray-600 text-sm">{feature}</span>
                  </div>
                ))}
              </div>

              {plan.isEnterprise && (
                <p className="text-sm text-gray-500 mb-6">
                  For context, enterprise agencies typically charge $15K–$50K/mo
                  for comparable services - our AI-driven platform delivers
                  competent insights at a fraction of the cost
                </p>
              )}

              <div className="mt-auto pt-4">
                <a
                  href={
                    plan.isEnterprise
                      ? config.pricing.enterpriceMailTo
                      : config.typeform.waitlistForm
                  }
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handlePlanClick(plan.isEnterprise)}
                  className={`block w-full text-center px-6 py-3 font-medium transition-all duration-300 rounded-lg ${
                    plan.highlighted
                      ? "bg-gradient-to-r from-red-600 to-red-700 text-white hover:shadow-lg hover:shadow-red-600/20"
                      : plan.isEnterprise
                      ? "bg-gradient-to-r from-gray-800 to-gray-900 text-white hover:shadow-lg hover:shadow-gray-900/20"
                      : "bg-gray-900 text-white hover:bg-black hover:shadow-lg"
                  }`}
                >
                  {plan.isEnterprise ? "Contact Sales" : "Request Demo"}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="max-w-3xl mx-auto">
        {!showCalculator ? (
          <button
            onClick={() => setShowCalculator(true)}
            className="flex items-center gap-2 mx-auto bg-white rounded-lg px-6 py-3 shadow-sm border border-gray-200 hover:shadow-md transition-all duration-300 text-gray-900 font-medium"
          >
            <Calculator className="h-5 w-5 text-red-600" />
            Show Cost Savings Calculator
          </button>
        ) : (
          <CostCalculator />
        )}
      </div>

      <div className="text-center mt-12">
        <div className="inline-flex items-center bg-gray-100 rounded-full px-6 py-2 gap-2">
          <Check className="h-4 w-4 text-green-600" />
          <p className="text-gray-700 text-sm">
            7-day free trial • No long-term contract
          </p>
        </div>
      </div>
    </section>
  );
};

export default EnhancedPricingSection;
