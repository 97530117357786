import { Link } from "react-router-dom";
import { Mail, ChevronRight, ExternalLink } from "lucide-react";
import { config } from "../../config";

const CommonFooter = () => {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
          {/* Brand Column */}
          <div className="col-span-1">
            <div className="flex items-center gap-2 mb-6">
              <Link to="/" className="text-2xl font-bold text-black">
                {config.labels.brandName}
              </Link>
            </div>
            <p className="text-gray-600 mb-6">
              AdInsights.ai is an AI-powered platform that helps digital
              advertisers generate, manage, and optimize multi-platform digital
              ad campaign strategies.
            </p>
          </div>

          {/* Resources Column */}
          <div className="col-span-1">
            <h3 className="font-semibold text-lg mb-4">Resources</h3>
            <ul className="space-y-3">
              {[
                { name: "Blog", url: config.links.blog, external: true },
                {
                  name: "Referral Program",
                  url: config.links.refer,
                  external: true,
                },
                { name: "Legal", url: config.links.legal, external: false },
              ].map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.url}
                    target={item.external ? "_blank" : "_self"}
                    className="text-gray-600 hover:text-red-600 transition-colors duration-200 flex items-center gap-1 group"
                  >
                    <ChevronRight className="h-3 w-3 text-gray-400 group-hover:text-red-600" />
                    <span>{item.name}</span>
                    {item.external && (
                      <ExternalLink className="h-3 w-3 text-gray-400 group-hover:text-red-600" />
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Column */}
          <div className="col-span-1">
            <h3 className="font-semibold text-lg mb-4">Contact Us</h3>
            <ul className="space-y-3">
              <li>
                <a
                  href="mailto:support@adinsights.ai"
                  className="text-gray-600 hover:text-red-600 transition-colors duration-200 flex items-center gap-2"
                >
                  <Mail className="h-4 w-4 text-gray-400" />
                  <span>support@adinsights.ai</span>
                </a>
              </li>
              <li className="pt-4">
                <a
                  href="mailto:team@adinsights.ai?subject=Referral Program Application&body=Hi, I'd like to join the referral program.%0D%0A%0D%0APlease find my details below:%0D%0A%0D%0AEmail: %0D%0ASocial Media Profiles: %0D%0A%0D%0AThank you!"
                  className="inline-block bg-gray-100 hover:bg-gray-200 text-gray-800 px-4 py-2 rounded-lg transition-colors duration-200"
                >
                  Join our Referral Program
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-4 border-t border-gray-200 pt-8">
          <p className="text-sm text-gray-600">
            © {new Date().getFullYear()} {config.labels.brandName}. All rights
            reserved.
          </p>
          <div className="flex gap-6 text-sm">
            <Link
              to="/legal"
              className="text-gray-600 hover:text-red-600 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
            <Link
              to="/legal"
              className="text-gray-600 hover:text-red-600 transition-colors duration-200"
            >
              Terms of Service
            </Link>
            <Link
              to="/legal"
              className="text-gray-600 hover:text-red-600 transition-colors duration-200"
            >
              Cookie Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CommonFooter;
