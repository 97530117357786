import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { X, Menu, ExternalLink } from "lucide-react";
import { config } from "../../config";
import mixpanel from "mixpanel-browser";

// Constants for navigation
const navItems = [
  { name: "Blog", href: config.links.blog, external: true },
  { name: "Refer", href: config.links.refer, external: true },
  { name: "Legal", href: config.links.legal, external: false },
];

// Header Component
const CommonHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "bg-white/95 backdrop-blur-sm shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center gap-2">
          <Link to="/" className="text-2xl font-bold text-black">
            AdInsights.ai
          </Link>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex flex-row items-center gap-6">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              target={item.external ? "_blank" : "_self"}
              className="text-gray-800 px-3 py-2 text-sm font-medium hover:text-red-600 transition-colors duration-200 flex items-center gap-1 group"
              onClick={(_: any) => {
                if (process.env.NODE_ENV === "production") {
                  mixpanel.track("Access Request Clicked");
                }
              }}
            >
              {item.name === "Refer" ? "Referral Program" : item.name}
              {item.external && (
                <ExternalLink className="h-3 w-3 text-gray-400 group-hover:text-red-600 transition-colors duration-200" />
              )}
            </Link>
          ))}

          <Link
            to={config.typeform.waitlistForm}
            target="_blank"
            className="ml-4 bg-gradient-to-r from-red-600 to-red-700 text-white px-5 py-2 text-sm font-medium rounded-lg hover:from-red-700 hover:to-red-800 transition-all duration-300 shadow-md hover:shadow-lg"
          >
            Request Access
          </Link>
        </div>

        {/* Mobile menu button and navigation container */}
        <div className="lg:hidden flex items-center gap-2">
          <Link
            to={config.typeform.waitlistForm}
            target="_blank"
            className="mr-2 bg-gradient-to-r from-red-600 to-red-700 text-white px-4 py-2 text-sm font-medium rounded-lg"
            onClick={(_: any) => {
              if (process.env.NODE_ENV === "production") {
                mixpanel.track("Access Request Clicked");
              }
            }}
          >
            Access
          </Link>

          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2 rounded-lg text-black hover:bg-gray-100 focus:outline-none transition-colors duration-200"
          >
            <span className="sr-only">Open menu</span>
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="lg:hidden border-t border-gray-200 bg-white/95 backdrop-blur-sm shadow-lg"
          >
            <div className="px-4 py-4 space-y-2">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  target={item.external ? "_blank" : "_self"}
                  className="flex items-center justify-between px-4 py-3 text-base font-medium text-gray-800 hover:text-red-600 hover:bg-gray-50 rounded-lg transition-colors duration-200"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <span>
                    {item.name === "Refer" ? "Referral Program" : item.name}
                  </span>
                  {item.external && (
                    <ExternalLink className="h-4 w-4 text-gray-400" />
                  )}
                </Link>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default CommonHeader;
