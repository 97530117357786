import { useState, useEffect } from "react";
import {
  Target,
  Check,
  Database,
  Bot,
  TrendingUp,
  DollarSign,
  BarChart,
  Mail,
  Zap,
  PieChart,
  RefreshCw,
  X,
  SendHorizonal,
  BrainCircuit,
  Rocket,
  Sparkles,
  Brain,
  FileSpreadsheet,
  Shield,
  Settings,
  Share2,
  Users,
  MessageCircle,
  Calendar,
  Briefcase,
  MailCheck,
  ChevronRight,
  ArrowRight,
  GitCompare,
  FormInput,
} from "lucide-react";
import mixpanel from "mixpanel-browser";
import { config } from "../../config";
import CommonFooter from "../../components/CommonFooter";
import CommonHeader from "../../components/CommonHeader";
import EnhancedPricingSection from "../../components/EnhancedPricingSection";

const MetricsHighlights = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12">
      {[
        {
          metric: "63% Higher ROAS",
          description: "For e-commerce clients within 60 days",
          icon: TrendingUp,
          color: "from-red-500 to-red-600",
        },
        {
          metric: "45% CAC Reduction",
          description: "Average across service businesses in 45 days",
          icon: DollarSign,
          color: "from-red-600 to-red-700",
        },
        {
          metric: "3x Conversion Rate",
          description: "For solopreneurs after implementing AI targeting",
          icon: BarChart,
          color: "from-red-500 to-red-600",
        },
      ].map((item, index) => (
        <div
          key={index}
          className="bg-white rounded-lg shadow-md p-4 flex items-center gap-4 border border-gray-100 hover:shadow-lg transition-all duration-300"
        >
          <div
            className={`w-12 h-12 rounded-full bg-gradient-to-r ${item.color} flex items-center justify-center`}
          >
            <item.icon className="h-6 w-6 text-white" />
          </div>
          <div>
            <p className="text-xl font-bold text-gray-900">{item.metric}</p>
            <p className="text-sm text-gray-600">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const PlatformGrid = () => {
  const platforms = [
    { name: "Google Ads", icon: Database }, // ~$180B
    { name: "Facebook Ads", icon: Target }, // ~$75B
    { name: "YouTube Ads", icon: Mail }, // ~$30B
    { name: "Instagram Ads", icon: PieChart }, // ~$25B
    { name: "TikTok Ads", icon: Zap }, // ~$15B
    { name: "LinkedIn Ads", icon: Bot }, // ~$4B
    { name: "Snapchat Ads", icon: BarChart }, // ~$4B
    { name: "X Ads", icon: SendHorizonal }, // ~$3B
    { name: "Reddit Ads", icon: RefreshCw }, // ~$0.5B
  ];

  return (
    <div className="grid grid-cols-3 md:grid-cols-3 gap-8 max-w-4xl mx-auto mt-16">
      {platforms.map((platform, index) => (
        <div
          key={index}
          className="flex flex-col items-center gap-3 group transition-transform duration-300 hover:scale-105"
        >
          <div className="w-16 h-16 flex items-center justify-center transition-all duration-300 bg-white rounded-lg shadow-md group-hover:shadow-lg group-hover:border-red-600 overflow-hidden">
            <platform.icon className="w-8 h-8 text-black group-hover:text-red-600 transition-all duration-300" />
          </div>
          <span className="text-sm font-medium text-gray-700">
            {platform.name}
          </span>
        </div>
      ))}
    </div>
  );
};

const HowItWorks = () => {
  return (
    <div className="py-16 bg-white mb-20 border-y border-gray-100">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          How <span className="text-red-600">AdInsights.ai</span> Works
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: Target,
              title: "ICP Research",
              text: "Analyzes your business goals and industry trends to create detailed ideal customer profiles and identify high-potential market segments",
            },
            {
              icon: Bot,
              title: "Ad Strategy",
              text: "Generates complete multi-platform strategies with targeting parameters and budget recommendations for each platform",
            },
            {
              icon: BarChart,
              title: "AI Insights",
              text: "Discover patterns across platforms and receive actionable recommendations",
            },
            {
              icon: GitCompare,
              title: "Competitive Analysis",
              text: "Performs in-depth competitor analysis using your business data, ICP insights, and competitor information to identify market opportunities and strategic advantages",
            },
          ].map((step, index) => (
            <div
              key={index}
              className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border-t-4 border-t-red-600 transform hover:-translate-y-2"
            >
              <div className="mb-6 flex justify-center">
                <div className="w-16 h-16 rounded-full bg-red-50 flex items-center justify-center">
                  <step.icon className="h-8 w-8 text-red-600" />
                </div>
              </div>
              <h3 className="text-xl font-semibold mb-4 text-center">
                {step.title}
              </h3>
              <p className="text-gray-600 text-center">{step.text}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Implementation Process */}
      <div className="mt-16">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          Implementation Process
        </h2>

        <div className="flex flex-col md:flex-row gap-6 max-w-4xl mx-auto">
          {[
            {
              title: "Fill In Business Details",
              description:
                "Fill out a simple form with your business details, including goals, target audience, etc.",
              icon: FormInput,
            },
            {
              title: "Review & Customize",
              description:
                "Review AI-generated strategies and insights, then customize them to your specific business needs and preferences.",
              icon: FileSpreadsheet,
            },
            {
              title: "Implement & Monitor",
              description:
                "Apply recommendations to your campaigns, monitor performance improvements, and iterate on successful campaigns by generating new strategies with the performance data.",
              icon: TrendingUp,
            },
          ].map((item, index) => (
            <div
              key={index}
              className="flex-1 bg-gray-50 p-6 rounded-xl border border-gray-100 relative"
            >
              <div className="flex items-start gap-4">
                <div className="w-10 h-10 bg-red-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <item.icon className="h-5 w-5 text-red-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-900 mb-2">
                    {item.title}
                  </h4>
                  <p className="text-sm text-gray-600">{item.description}</p>
                </div>
              </div>

              {index < 2 && (
                <div className="absolute right-4 top-1/2 transform -translate-y-1/2 hidden md:block">
                  <ArrowRight className="h-5 w-5 text-gray-400" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MarketingStrategiesSection = () => {
  return (
    <div className="relative py-16">
      <div className="absolute top-0 left-0 right-0 h-12 bg-gradient-to-r from-gray-50 to-white transform -skew-y-2"></div>

      <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">
        Beyond Ads:{" "}
        <span className="text-red-600 relative">
          Comprehensive Marketing Strategy Development
        </span>
      </h2>
      <p className="text-center text-gray-600 mb-12 max-w-3xl mx-auto">
        Our AI platform analyzes your business data to develop integrated
        marketing strategies across multiple channels - providing
        enterprise-grade marketing capabilities at a fraction of traditional
        costs
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
        {[
          {
            name: "Email Marketing",
            icon: MailCheck,
            description:
              "Develops targeted, data-driven email campaigns optimized for engagement and conversion performance.",
          },
          {
            name: "Influencer Marketing",
            icon: Users,
            description:
              "Creates strategic influencer partnerships based on audience alignment, performance metrics, and industry benchmarks.",
          },
          {
            name: "Affiliate Programs",
            icon: Share2,
            description:
              "Designs commission structures and partner programs optimized for your business model and market position.",
          },
        ].map((strategy, index) => (
          <div
            key={index}
            className="flex flex-col items-center group bg-white p-8 rounded-lg shadow-sm hover:shadow-lg transition-all duration-300"
          >
            <div className="w-16 h-16 bg-gray-50 rounded-full flex items-center justify-center mb-6 transition-all duration-300 group-hover:bg-red-50">
              <strategy.icon className="w-8 h-8 text-red-600 transition-all duration-300" />
            </div>

            <span className="text-lg font-semibold text-gray-800 mb-3">
              {strategy.name}
            </span>

            <p className="text-sm text-gray-600 text-center mb-4">
              {strategy.description}
            </p>

            <div className="mt-auto pt-4">
              <a
                href={config.typeform.waitlistForm}
                target="_blank"
                rel="noreferrer"
                className="text-sm font-medium text-red-600 flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                onClick={(_: any) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Learn More Clicked");
                  }
                }}
              >
                Learn more <ChevronRight className="h-4 w-4 ml-1" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const EarlyAccessSection = () => {
  return (
    <div className="bg-gradient-to-br from-black to-gray-900 text-white p-12 my-20 rounded-xl overflow-hidden relative">
      {/* Abstract pattern overlay */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-full h-full bg-grid-pattern"></div>
      </div>

      <div className="max-w-4xl mx-auto text-center relative z-10">
        <div className="inline-block bg-red-600/10 text-red-500 px-4 py-2 rounded-full text-sm font-medium mb-6 backdrop-blur-sm">
          Strategic Partnership Program (Limited Availability)
        </div>

        <h2 className="text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300">
          Direct Collaboration with Our Founding Team
        </h2>

        <p className="text-xl text-gray-300 mb-10">
          Gain privileged access to our founding team. Bypass standard support
          channels and work directly with the technical experts who developed
          the platform.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <div className="p-8 border border-gray-800 rounded-xl bg-gray-900/70 backdrop-blur-sm hover:border-red-600/30 transition-all duration-300 transform hover:-translate-y-1">
            <Briefcase className="h-8 w-8 text-red-500 mx-auto mb-4" />
            <div className="text-lg font-semibold mb-2">Executive Support</div>
            <div className="text-gray-400">
              Direct assistance from our leadership team
            </div>
          </div>

          <div className="p-8 border border-gray-800 rounded-xl bg-gray-900/70 backdrop-blur-sm hover:border-red-600/30 transition-all duration-300 transform hover:-translate-y-1">
            <MessageCircle className="h-8 w-8 text-red-500 mx-auto mb-4" />
            <div className="text-lg font-semibold mb-2">Strategy Sessions</div>
            <div className="text-gray-400">
              Scheduled 1:1 consultations with our team
            </div>
          </div>

          <div className="p-8 border border-gray-800 rounded-xl bg-gray-900/70 backdrop-blur-sm hover:border-red-600/30 transition-all duration-300 transform hover:-translate-y-1">
            <Calendar className="h-8 w-8 text-red-500 mx-auto mb-4" />
            <div className="text-lg font-semibold mb-2">Product Influence</div>
            <div className="text-gray-400">
              Shape our development roadmap and feature priorities
            </div>
          </div>
        </div>

        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col items-center gap-8">
            <div className="text-xl font-semibold bg-red-600/10 px-6 py-2 rounded-full backdrop-blur-sm">
              Limited to 20 partner companies - Limit Reached - Waitlist
              Available
            </div>

            <div className="space-y-4">
              <a
                href={config.typeform.waitlistForm}
                target="_blank"
                rel="noreferrer"
                className="inline-block bg-red-600 text-white px-10 py-4 rounded-lg font-medium hover:bg-red-700 transition-all duration-300 shadow-lg shadow-red-600/20"
              >
                Apply for Partnership
              </a>
              <p className="text-sm text-gray-400">
                Executive access • Strategic sessions • Product development
                input
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function LandingPage() {
  const [activeTab, setActiveTab] = useState<any>("research");
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const selectedTabImageDescription = () => {
    switch (activeTab) {
      case "research":
        return "The ICP research agent analyzes your business data to generate detailed ideal customer profiles - identifying target industries, key decision makers, and conversion timelines. Status tracking and filtering help manage multiple ICPs across your projects.";
      case "strategy":
        return "The ad strategy agent creates comprehensive multi-platform campaign strategies with detailed budgets, targeting, and performance metrics. View platform-specific recommendations across Google, Facebook, LinkedIn and more with projected ROI metrics.";
      case "insights":
        return "The AI insights agent analyzes campaign performance across platforms to identify winning patterns and optimization opportunities. Get platform-specific findings with confidence scores and clear recommended actions to improve results.";
      case "analysis":
        return "The competitive analysis agent performs in-depth competitor analysis using your business data and market insights to identify strategic advantages. Track competitive positioning, discover market opportunities, and get actionable recommendations for differentiation.";
      case "question":
        return "The AI questioning feature provides instant insights about your ICPs, analyzing detailed profiles of decision-makers in your target market. Get comprehensive information about professional roles, experience levels, tooling preferences, and business context to better understand and reach your ideal customers.";
      default:
        return "";
    }
  };

  useEffect(() => {
    mixpanel.init(config.mixpanel.token, {
      debug: process.env.NODE_ENV !== "production",
      ignore_dnt: process.env.NODE_ENV !== "production",
      track_pageview: true,
      persistence: "localStorage",
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 text-gray-900">
      <CommonHeader />

      {/** Hero Section */}
      <main className="pt-24">
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center mb-8">
            <div className="text-center md:text-left mb-8">
              <div className="inline-block bg-red-50 text-red-600 px-4 py-2 rounded-full text-sm font-medium mb-4">
                Built for SMBs, Solopreneurs & E-commerce
              </div>

              <h1 className="text-5xl md:text-6xl font-bold mb-6 text-black leading-tight">
                Strategic <span className="text-red-600">AI-Powered</span>{" "}
                Marketing for Growing Businesses
              </h1>

              <p className="text-xl text-gray-600 mb-6 max-w-2xl md:mx-0 mx-auto">
                Elevate your marketing performance with data-driven insights.
                AdInsights.ai delivers enterprise-level campaign intelligence
                tailored to your business scale and budget.
              </p>

              <p className="text-lg text-gray-600 mb-8">
                Access the capabilities of a dedicated marketing team for{" "}
                <span className="font-semibold">
                  a fraction of the traditional cost
                </span>
              </p>

              <div className="flex flex-wrap justify-start items-center gap-6 mb-8">
                <div className="flex items-center gap-2 bg-white py-2 px-4 rounded-lg shadow-sm">
                  <DollarSign className="h-5 w-5 text-red-600" />
                  <span className="text-sm text-gray-800">
                    Optimized Acquisition Costs
                  </span>
                </div>
                <div className="flex items-center gap-2 bg-white py-2 px-4 rounded-lg shadow-sm">
                  <TrendingUp className="h-5 w-5 text-red-600" />
                  <span className="text-sm text-gray-800">Enhanced ROAS</span>
                </div>
                <div className="flex items-center gap-2 bg-white py-2 px-4 rounded-lg shadow-sm">
                  <Database className="h-5 w-5 text-red-600" />
                  <span className="text-sm text-gray-800">
                    Rapid Implementation
                  </span>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-4">
                <a
                  href={config.typeform.waitlistForm}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block bg-gradient-to-r from-red-600 to-red-700 text-white px-8 py-4 text-lg font-medium hover:from-red-700 hover:to-red-800 transition-all duration-300 rounded-lg shadow-lg shadow-red-600/20"
                >
                  Start 7-Day Free Trial
                </a>
                <a
                  href="#demo"
                  className="inline-block bg-white text-black border border-gray-200 px-8 py-4 text-lg font-medium hover:bg-gray-50 transition-all duration-300 rounded-lg"
                >
                  Watch Demo
                </a>
              </div>
            </div>

            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-br from-red-500/5 to-blue-500/5 rounded-2xl transform -rotate-2"></div>
              <div className="relative bg-white p-6 rounded-2xl shadow-xl overflow-hidden">
                <div className="absolute top-0 right-0 bg-gradient-to-bl from-red-600/10 to-transparent w-32 h-32 rounded-bl-3xl"></div>

                <div className="relative">
                  <div className="bg-gray-100 h-8 w-full rounded-md mb-4 flex items-center px-3">
                    <div className="flex space-x-2">
                      <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                      <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                      <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-3 mb-4">
                    {[1, 2, 3].map((item) => (
                      <div
                        key={item}
                        className="h-24 bg-gray-100 rounded-lg p-3 flex flex-col"
                      >
                        <div className="w-8 h-2 bg-red-200 rounded mb-2"></div>
                        <div className="w-12 h-3 bg-gray-200 rounded mb-2"></div>
                        <div className="mt-auto w-full h-8 bg-red-100 rounded-md"></div>
                      </div>
                    ))}
                  </div>

                  <div className="h-40 bg-gray-100 rounded-lg p-4 flex flex-col">
                    <div className="flex justify-between mb-4">
                      <div className="w-24 h-3 bg-gray-300 rounded"></div>
                      <div className="w-16 h-3 bg-red-200 rounded"></div>
                    </div>
                    <div className="flex-grow flex items-center justify-center">
                      <div className="w-full h-24 bg-gradient-to-r from-red-100 to-red-200 rounded-lg flex items-center justify-center">
                        <TrendingUp className="h-8 w-8 text-red-600" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Metric Highlights */}
          <MetricsHighlights />

          {/* How It Works */}
          <HowItWorks />

          {/* Industry Focus */}
          <div className="bg-white border border-gray-200 p-8 rounded-xl shadow-lg relative overflow-hidden mb-20">
            <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-red-500 to-red-600"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <h2 className="text-3xl font-semibold mb-8 text-black">
                  Built specifically for:
                </h2>
                <ul className="space-y-6">
                  {[
                    "Solopreneurs (Market Validation)",
                    "Small-to-Medium Businesses",
                    "Growing E-commerce Stores",
                    "D2C Brands",
                    "Service-Based SMBs",
                    "Bootstrapped Startups",
                    "Marketing Teams of 1-3 People",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start group">
                      <div className="flex-shrink-0 w-8 h-8 bg-gradient-to-r from-red-500 to-red-600 rounded-md flex items-center justify-center mr-4 shadow-md group-hover:shadow-lg transition-all duration-300">
                        <Check className="h-5 w-5 text-white" />
                      </div>
                      <span className="text-lg text-gray-700 group-hover:text-black transition-colors duration-300">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative w-full">
                <div className="bg-gray-50 rounded-xl shadow-lg p-6">
                  <div className="flex flex-wrap gap-2 mb-6">
                    {[
                      "research",
                      "strategy",
                      "insights",
                      "analysis",
                      "question",
                    ].map((tab) => (
                      <button
                        key={tab}
                        onClick={() => setActiveTab(tab)}
                        className={`px-4 py-3 text-sm font-medium rounded-lg transition-all duration-300 ${
                          activeTab === tab
                            ? "bg-gradient-to-r from-red-600 to-red-700 text-white shadow-md"
                            : "text-gray-600 hover:text-black hover:bg-gray-100"
                        }`}
                      >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </button>
                    ))}
                  </div>
                  <div>
                    <div
                      className="aspect-video bg-white border border-gray-200 rounded-lg overflow-hidden cursor-pointer shadow-md hover:shadow-lg transition-all duration-300"
                      onClick={() =>
                        setSelectedImage(`${activeTab}-example.webp`)
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + `${activeTab}-example.webp`
                        }
                        alt={`${activeTab} screenshot`}
                        className="w-full h-full object-contain"
                      />
                    </div>

                    {selectedImage && (
                      <div
                        className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
                        onClick={() => setSelectedImage(null)}
                      >
                        <button
                          className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors bg-black/50 p-2 rounded-full"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedImage(null);
                          }}
                        >
                          <X className="h-6 w-6" />
                        </button>
                        <img
                          src={process.env.PUBLIC_URL + selectedImage}
                          alt="Full size view"
                          className="max-w-full max-h-[90vh] object-contain rounded-lg shadow-2xl"
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt-6 text-sm text-gray-600 p-4 bg-white rounded-lg shadow-sm">
                    {selectedTabImageDescription()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Platform Grid */}
          <div className="mb-20 relative">
            <div className="absolute inset-0 bg-gradient-to-r from-gray-50 to-white transform -skew-y-3 -z-10"></div>
            <h2 className="text-3xl font-bold text-center mb-8">
              One Platform,{" "}
              <span className="text-red-600 relative">
                All Your Ad Channels
              </span>
            </h2>
            <PlatformGrid />
          </div>

          <MarketingStrategiesSection />

          {/* Demo */}
          <section
            id="demo"
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 relative"
          >
            <h2 className="text-4xl font-bold text-center mb-6 text-black">
              See <span className="text-red-600">AdInsights.ai</span> in Action
            </h2>
            <p className="text-center text-gray-600 italic mb-4">
              Watch how our platform automates campaign creation and
              optimization across multiple ad platforms
            </p>
            <p className="text-center text-sm text-gray-500 mb-8">
              Our platform and AI agents are continuously evolving. We regularly
              update this demo to showcase the latest features and capabilities.
            </p>
            <div className="relative aspect-video rounded-xl overflow-hidden">
              <video
                controls
                id="demo-video"
                className="w-full h-full fit-cover"
                src={config.videos.demoSource}
                poster={process.env.PUBLIC_URL + config.videos.demoPoster}
                onClick={(_) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Video Player Clicked");
                    (window as any).heap.track("Video Player Clicked");
                  }
                }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </section>

          {/* How is AdInsights.ai different? */}
          <div className="bg-gradient-to-br from-gray-900 to-black text-white p-12 rounded-xl shadow-xl mb-20 relative overflow-hidden">
            <div className="absolute inset-0 opacity-10">
              <div className="absolute left-0 top-0 w-full h-full bg-[radial-gradient(circle_at_30%_50%,_rgba(255,0,0,0.1),_transparent_40%)]"></div>
              <div className="absolute right-0 bottom-0 w-full h-full bg-[radial-gradient(circle_at_70%_50%,_rgba(0,0,255,0.1),_transparent_40%)]"></div>
            </div>

            <h2 className="text-4xl font-bold text-center mb-12 relative">
              How is AdInsights.ai different?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12 relative">
              <div>
                <h3 className="text-2xl font-semibold mb-8">
                  How AdInsights.ai Outperforms Generic AI Tools
                </h3>

                <div className="space-y-8">
                  <div className="flex gap-6 group">
                    <div className="flex-shrink-0 w-12 h-12 bg-red-600/20 rounded-xl flex items-center justify-center group-hover:bg-red-600/30 transition-colors duration-300">
                      <Brain className="h-6 w-6 text-red-500" />
                    </div>
                    <div>
                      <h4 className="font-semibold mb-2 text-xl">
                        Strategy Over Creativity
                      </h4>
                      <p className="text-gray-300">
                        Unlike AI tools that only generate ads, we focus on the
                        strategic foundation of who to target, how to allocate
                        budgets, and what's working across platforms.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-6 group">
                    <div className="flex-shrink-0 w-12 h-12 bg-red-600/20 rounded-xl flex items-center justify-center group-hover:bg-red-600/30 transition-colors duration-300">
                      <FileSpreadsheet className="h-6 w-6 text-red-500" />
                    </div>
                    <div>
                      <h4 className="font-semibold mb-2 text-xl">
                        Built on Real Campaign Data
                      </h4>
                      <p className="text-gray-300">
                        Available models analyzed thousands of anonymized
                        campaigns, providing the power of predictive budget
                        allocation and adaptive benchmarks specific to your
                        industry.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-6 group">
                    <div className="flex-shrink-0 w-12 h-12 bg-red-600/20 rounded-xl flex items-center justify-center group-hover:bg-red-600/30 transition-colors duration-300">
                      <Shield className="h-6 w-6 text-red-500" />
                    </div>
                    <div>
                      <h4 className="font-semibold mb-2 text-xl">
                        Your Data, Your Control
                      </h4>
                      <p className="text-gray-300">
                        Export campaign reports from any platform. Our AI
                        analyzes your campaign's performance and industry trends
                        to build a strategic insights for you.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-6 group">
                    <div className="flex-shrink-0 w-12 h-12 bg-red-600/20 rounded-xl flex items-center justify-center group-hover:bg-red-600/30 transition-colors duration-300">
                      <Settings className="h-6 w-6 text-red-500" />
                    </div>
                    <div>
                      <h4 className="font-semibold mb-2 text-xl">
                        Flexible Model Selection
                      </h4>
                      <p className="text-gray-300">
                        Choose the AI model that best fits your needs and
                        budget. From cost-efficient GPT models to advanced
                        Claude, customize your experience while maintaining
                        high-quality insights.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <div className="relative w-full max-w-md">
                  <div className="absolute inset-0 bg-gradient-to-br from-red-600/30 to-blue-600/30 rounded-3xl transform rotate-6"></div>
                  <div className="relative bg-gray-900 rounded-2xl p-8">
                    <h4 className="font-semibold mb-6 flex items-center gap-2 text-xl">
                      <RefreshCw className="h-5 w-5 text-red-500" />
                      The Insights Flywheel
                    </h4>
                    <div className="space-y-6">
                      {[
                        "ICP Research identifies ideal targets",
                        "Ad Strategy optimizes channel mix",
                        "Campaign data feeds insights",
                        "AI models improve with each cycle",
                      ].map((item, i) => (
                        <div key={i} className="flex items-center gap-4 group">
                          <div className="h-10 w-10 rounded-full bg-gradient-to-r from-red-600 to-red-700 flex items-center justify-center shadow-lg group-hover:shadow-red-600/20 transition-all duration-300">
                            {i + 1}
                          </div>
                          <div className="py-2 px-4 rounded-lg bg-white/5 group-hover:bg-white/10 transition-colors duration-300 flex-1">
                            <p>{item}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Key Features & Benefits */}
          <div className="mb-20 bg-white border border-gray-200 p-8 rounded-xl shadow-lg relative overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-red-500 to-red-600"></div>
            <h2 className="text-3xl font-semibold mb-10 text-center">
              AI-Powered Marketing Intelligence for Growing Businesses
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <div>
                <ul className="space-y-6">
                  {[
                    {
                      title: "Strategic Audience Intelligence",
                      description:
                        "Develop precise Ideal Customer Profiles (ICPs) that identify your optimal audience segments, increasing efficiency and reducing wasted ad spend.",
                    },
                    {
                      title: "Integrated Platform Management",
                      description:
                        "Centralize campaign oversight across Google, Facebook, Instagram, TikTok, and more from a single intuitive dashboard.",
                    },
                    {
                      title: "Data-Driven Strategy Development",
                      description:
                        "Leverage AI-generated marketing strategies with detailed targeting parameters and resource allocation recommendations for optimal performance.",
                    },
                  ].map((item, index) => (
                    <li key={index} className="flex items-start group">
                      <div className="flex-shrink-0 w-8 h-8 bg-gradient-to-r from-red-500 to-red-600 rounded-md flex items-center justify-center mr-4 shadow-md group-hover:shadow-lg transition-all duration-300">
                        <Check className="h-5 w-5 text-white" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800 mb-1">
                          {item.title}
                        </h3>
                        <p className="text-gray-600">{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <ul className="space-y-6">
                  {[
                    {
                      title: "Performance Pattern Recognition",
                      description:
                        "Identify cross-platform patterns and actionable optimization opportunities that enhance ROAS and reduce customer acquisition costs.",
                    },
                    {
                      title: "Competitive Market Analysis",
                      description:
                        "Gain insights into competitor strategies and identify market opportunities to establish strategic advantages in your industry.",
                    },
                    {
                      title: "On-Demand Marketing Intelligence",
                      description:
                        "Access immediate, data-backed answers to your marketing questions through our AI assistant, enabling faster and more informed decisions.",
                    },
                  ].map((item, index) => (
                    <li key={index} className="flex items-start group">
                      <div className="flex-shrink-0 w-8 h-8 bg-gradient-to-r from-red-500 to-red-600 rounded-md flex items-center justify-center mr-4 shadow-md group-hover:shadow-lg transition-all duration-300">
                        <Check className="h-5 w-5 text-white" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800 mb-1">
                          {item.title}
                        </h3>
                        <p className="text-gray-600">{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* AI Q&A Feature Section */}
          <div className="mb-20 relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-gray-50 to-white transform -skew-y-3 -z-10"></div>

            <h2 className="text-3xl font-bold text-center mb-12">
              Ask AI About Your{" "}
              <span className="text-red-600">Marketing Data</span>
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-7xl mx-auto px-4">
              <div>
                <h3 className="text-2xl font-semibold mb-8 text-gray-800">
                  Your Marketing Brain, On-Demand
                </h3>

                <div className="space-y-8">
                  {[
                    {
                      title: "ICP Research Q&A",
                      examples: [
                        "What industries showed highest LTV in our ICP analysis?",
                        "Which customer segments have the shortest sales cycles?",
                        "What are the common pain points across our top ICPs?",
                      ],
                    },
                    {
                      title: "Ad Strategy Intelligence",
                      examples: [
                        "Why did you recommend increasing Facebook's budget by 40%?",
                        "What's the reasoning behind the TikTok targeting parameters?",
                        "Compare the strategy differences between Facebook and Instagram",
                      ],
                    },
                    {
                      title: "Performance Insights",
                      examples: [
                        "Which creatives are driving the highest ROAS?",
                        "Why did you suggest reallocating budget from LinkedIn to TikTok?",
                        "What patterns did you find in our top-performing campaigns?",
                      ],
                    },
                    {
                      title: "Competitive Analysis",
                      examples: [
                        "What are our key differentiators vs Competitor X?",
                        "Which competitor tactics should we counter first?",
                        "Where are the biggest market opportunities vs competitors?",
                      ],
                    },
                  ].map((category, index) => (
                    <div
                      key={index}
                      className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300"
                    >
                      <h4 className="font-semibold mb-4 flex items-center gap-2">
                        <Brain className="h-5 w-5 text-red-600" />
                        {category.title}
                      </h4>
                      <ul className="space-y-3">
                        {category.examples.map((example, i) => (
                          <li key={i} className="flex items-start gap-3">
                            <span className="text-sm py-1 px-3 bg-gray-50 border border-gray-200 rounded-full hover:bg-red-50 hover:border-red-200 transition-colors duration-200 cursor-pointer">
                              {example}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <div className="sticky top-8">
                  <div className="bg-gradient-to-br from-gray-900 to-black text-white p-8 rounded-xl shadow-xl mb-8 overflow-hidden relative">
                    <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-bl from-red-600/10 to-transparent rounded-bl-full"></div>

                    <h4 className="text-xl font-semibold mb-6 relative">
                      Perfect For Small Business Marketing
                    </h4>

                    <div className="space-y-6 relative">
                      <div className="bg-white/5 p-4 rounded-lg hover:bg-white/10 transition-colors duration-300">
                        <h5 className="font-medium mb-2 flex items-center gap-2">
                          <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                          E-commerce & D2C
                        </h5>
                        <p className="text-gray-300 text-sm">
                          Understand customer segments better and optimize
                          multi-channel strategy for maximum ROAS
                        </p>
                      </div>

                      <div className="bg-white/5 p-4 rounded-lg hover:bg-white/10 transition-colors duration-300">
                        <h5 className="font-medium mb-2 flex items-center gap-2">
                          <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                          Service-Based SMBs
                        </h5>
                        <p className="text-gray-300 text-sm">
                          Quick answers about marketing strategies tailored to
                          your local service area and specific client needs
                        </p>
                      </div>

                      <div className="bg-white/5 p-4 rounded-lg hover:bg-white/10 transition-colors duration-300">
                        <h5 className="font-medium mb-2 flex items-center gap-2">
                          <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                          Solopreneurs
                        </h5>
                        <p className="text-gray-300 text-sm">
                          Get deep insights into customer targeting without
                          needing to hire a marketing team
                        </p>
                      </div>

                      <div className="bg-white/5 p-4 rounded-lg hover:bg-white/10 transition-colors duration-300">
                        <h5 className="font-medium mb-2 flex items-center gap-2">
                          <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                          Bootstrapped Startups
                        </h5>
                        <p className="text-gray-300 text-sm">
                          Strategic understanding of budget allocation to
                          maximize your limited marketing funds
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gradient-to-br from-red-600 to-red-700 p-8 rounded-xl shadow-xl text-white">
                    <div className="flex items-center gap-3 mb-4">
                      <div className="w-12 h-12 bg-white/10 rounded-full flex items-center justify-center">
                        <Brain className="h-6 w-6 text-white" />
                      </div>
                      <div>
                        <h4 className="text-white font-semibold">
                          Contextual Understanding
                        </h4>
                        <p className="text-sm text-red-100">
                          AI retains full context of your data
                        </p>
                      </div>
                    </div>

                    <div className="space-y-4 text-sm">
                      <p className="flex items-start gap-2">
                        <Check className="h-5 w-5 text-white flex-shrink-0 mt-0.5" />
                        <span>
                          Understands relationships between ICPs and strategies
                        </span>
                      </p>
                      <p className="flex items-start gap-2">
                        <Check className="h-5 w-5 text-white flex-shrink-0 mt-0.5" />
                        <span>Connects insights across multiple campaigns</span>
                      </p>
                      <p className="flex items-start gap-2">
                        <Check className="h-5 w-5 text-white flex-shrink-0 mt-0.5" />
                        <span>
                          Links competitive analysis to strategic decisions
                        </span>
                      </p>
                      <p className="flex items-start gap-2">
                        <Check className="h-5 w-5 text-white flex-shrink-0 mt-0.5" />
                        <span>Provides data-backed recommendations</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Model Flexibility */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Flexible{" "}
              <span className="text-red-600">AI Model Infrastructure</span>
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 max-w-7xl mx-auto px-4">
              {[
                {
                  icon: Bot,
                  title: "Claude",
                  text: "Advanced reasoning for strategic ad targeting and real-time performance analysis with collaborative artifact generation for campaign optimization",
                  color: "from-purple-500 to-purple-600",
                  hover: "hover:shadow-purple-500/20",
                },
                {
                  icon: Sparkles,
                  title: "Gemini",
                  text: "Native cross-platform creative analysis and optimization, enhancing ad performance through unified processing of text, image, and video content",
                  color: "from-blue-500 to-blue-600",
                  hover: "hover:shadow-blue-500/20",
                },
                {
                  icon: BrainCircuit,
                  title: "GPT",
                  text: "Cost-efficient processing of complex marketing data with 128k context windows for comprehensive campaign analysis and ICP development at 60% lower cost",
                  color: "from-green-500 to-green-600",
                  hover: "hover:shadow-green-500/20",
                },
                {
                  icon: Rocket,
                  title: "DeepSeek",
                  text: "Efficient processing of vast campaign datasets with 5x cost savings, enabling precise audience segmentation and advanced performance forecasting",
                  color: "from-amber-500 to-amber-600",
                  hover: "hover:shadow-amber-500/20",
                },
              ].map((model, index) => (
                <div
                  key={index}
                  className={`bg-white p-8 rounded-xl shadow-lg ${model.hover} hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1`}
                >
                  <div className="mb-6 flex justify-center">
                    <div
                      className={`w-16 h-16 rounded-full bg-gradient-to-r ${model.color} flex items-center justify-center shadow-lg`}
                    >
                      <model.icon className="h-8 w-8 text-white" />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-center">
                    {model.title}
                  </h3>
                  <p className="text-gray-600 text-center">{model.text}</p>
                </div>
              ))}
            </div>

            <p className="text-center text-gray-500 mt-8">
              *Requires API key (manually, we do not store API keys for security
              reasons)
            </p>
          </div>

          {/* Pricing */}
          <EnhancedPricingSection />

          <EarlyAccessSection />

          {/* FAQs for Small Businesses */}
          <div className="mb-20 bg-white p-8 rounded-xl shadow-lg border border-gray-200">
            <h2 className="text-3xl font-bold text-center mb-10">
              Frequently Asked Questions
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
              {[
                {
                  question:
                    "Is AdInsights.ai appropriate for independent professionals?",
                  answer:
                    "Absolutely. We've designed AdInsights.ai to be intuitive and accessible for independent professionals and small teams. Our onboarding process ensures you can quickly implement effective marketing strategies regardless of your technical background.",
                },
                {
                  question:
                    "How does AdInsights.ai compare to larger enterprise marketing solutions?",
                  answer:
                    "AdInsights.ai delivers enterprise-level marketing intelligence at a price point designed specifically for growing businesses. Our AI platform provides the strategic insights and campaign optimization capabilities that traditionally required large marketing teams or agency partnerships.",
                },
                {
                  question:
                    "Is AdInsights.ai suitable for those new to digital advertising?",
                  answer:
                    "Yes, AdInsights.ai is ideal for businesses at any experience level. The platform guides you through creating optimized strategies from day one, helping you avoid common inefficiencies while implementing industry best practices.",
                },
                {
                  question: "What performance improvements can I expect?",
                  answer:
                    "While results vary by industry and baseline performance, our clients typically experience significant improvements in return on ad spend, reductions in customer acquisition costs, and increased conversion rates. Our case studies showcase real-world performance improvements across different business types.",
                },
                {
                  question: "Does using the platform require AI expertise?",
                  answer:
                    "No technical AI knowledge is required. The platform features an intuitive interface with straightforward visualizations and natural language interaction. You can ask marketing questions and receive data-backed insights without any specialized technical background.",
                },
                {
                  question: "Is AdInsights.ai effective for local businesses?",
                  answer:
                    "Yes, AdInsights.ai is effective for businesses of all sizes and geographic focuses. For local businesses, our platform helps optimize geo-targeting, identify high-value local customer segments, and maximize your regional marketing impact.",
                },
              ].map((faq, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-xl">
                  <h3 className="text-lg font-semibold mb-3 text-gray-800">
                    {faq.question}
                  </h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>

      <CommonFooter />
    </div>
  );
}

export default LandingPage;
